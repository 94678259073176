import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { BrowserWrapper } from "./BrowserWrapper"

interface IExperience {
  role: string
  company: string
  period: string
  location: string
}

interface IEducation {
  college_name: string
  degree: string
  period: string
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  siteLink: string
  experience: IExperience[]
  education: IEducation[]
}

export const LinkedinExperience: React.FC<IProps> = ({
  siteLink,
  experience,
  education,
  ...rest
}) => {
  return (
    <div {...rest}>
      <BrowserWrapper siteLink={siteLink} width={430} height="auto">
        <div className="linkedin_experience">
          {experience.length > 0 ? <b className="title">Experience</b> : null}
          <ul>
            {experience.map((item, index) => (
              <li key={index}>
                <b>{item.role}</b>
                <small>{item.company}</small>
                <small className="light">{item.period}</small>
                <small className="light">{item.location}</small>
              </li>
            ))}
          </ul>
          {education.length > 0 ? <b className="title">Eduction</b> : null}
          <ul>
            {education.map((item, index) => (
              <li key={index}>
                <b>{item.college_name}</b>
                <small>{item.degree}</small>
                <small className="light">{item.period}</small>
              </li>
            ))}
          </ul>
        </div>
      </BrowserWrapper>
    </div>
  )
}
