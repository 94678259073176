import * as THREE from "three"

export const DEG2RAD = Math.PI / 180

export function visibilityForCoordinate(
  lng: number,
  lat: number,
  data: ImageData | null
) {
  if (!data) {
    return false
  }

  const i = 4 * data.width

  const rTemp = ((lng + 180) / 360) * data.width + 0.5
  const r = parseInt(rTemp.toString())

  const aTemp = ((lat + 90) / 180) * data.height - 0.5
  const a = data.height - parseInt(aTemp.toString())

  const sTemp = i * (a - 1) + 4 * r
  const s = parseInt(sTemp.toString()) + 3

  return data.data[s] > 90
}

export function getImageData(img: any) {
  const el = document.createElement("canvas").getContext("2d")

  if (!el) {
    return null
  }

  el.canvas.width = img.width
  el.canvas.height = img.height
  el.drawImage(img, 0, 0, img.width, img.height)

  return el.getImageData(0, 0, img.width, img.height)
}

export function calc_pos(
  lat: number,
  lng: number,
  globeRadius: number,
  vec?: THREE.Vector3
) {
  vec = vec || new THREE.Vector3()

  const vertical = (90 - lat) * DEG2RAD
  const horizontal = (lng + 180) * DEG2RAD

  vec.set(
    -globeRadius * Math.sin(vertical) * Math.cos(horizontal),
    globeRadius * Math.cos(vertical),
    globeRadius * Math.sin(vertical) * Math.sin(horizontal)
  )

  return vec
}

export const toRadians = (angle: number) => {
  return angle * (Math.PI / 180)
}

export const toDegrees = (angle: number) => {
  return angle * (180 / Math.PI)
}
