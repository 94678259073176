export const WINDOW_OBJECT_KEY = "dn"

export const CONSTANTS = {
  CLASSNAME_PREFIX: WINDOW_OBJECT_KEY,
  SMOOTH_SCROLL_CONTEXT: null,
}

export type ConstantType = keyof typeof CONSTANTS

type GlobalConstants = {
  [key: string]: string | any
}

export const initGlobals = (): void => {
  if (typeof window !== `undefined`) {
    if (!window[WINDOW_OBJECT_KEY]) {
      setGlobals()
    }
  }
}

export const setGlobals = (): void => {
  if (typeof window !== `undefined`) {
    window[WINDOW_OBJECT_KEY] = {
      ...CONSTANTS,
    }
  }
}

export const getGlobals = (key: ConstantType): GlobalConstants => {
  initGlobals()
  if (typeof window !== `undefined`) {
    return window[WINDOW_OBJECT_KEY][key]
  }
  return null
}

export const updateGlobals = (key: ConstantType, value: string): void => {
  initGlobals()
  if (typeof window !== `undefined`) {
    window[WINDOW_OBJECT_KEY] = {
      ...window[WINDOW_OBJECT_KEY],
      [key]: value,
    }
  }
}

export const EVENTS = {
  onSmoothScrollInit: "onSmoothScrollInit",
}
