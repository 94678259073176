import React from "react"
import { useState } from "react"
import { useCallback } from "react"
import { useRef } from "react"

interface ISource {
  src: string
  type: "video/mp4" | "video/webm" | "video/ogg" | string
}

interface IProps {
  poster: string
  sources: ISource[]
}

export const Phone: React.FC<IProps> = ({ poster, sources }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      var isPlaying =
        videoRef.current.currentTime > 0 &&
        !videoRef.current.paused &&
        !videoRef.current.ended &&
        videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA
      if (!isPlaying) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, videoRef.current)

  return (
    <div className="iphone-container">
      <video
        controls={false}
        autoPlay={false}
        muted={true}
        playsInline={true}
        poster={poster}
        ref={videoRef}
        onClick={togglePlay}
        onPlay={() => {
          setIsPlaying(true)
        }}
        onPause={() => {
          setIsPlaying(false)
        }}
        className={!isPlaying ? "video isPaused" : "video"}
      >
        {sources.map(source => (
          <source key={source.type} src={source.src} type={source.type} />
        ))}
      </video>
    </div>
  )
}
