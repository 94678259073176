import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import * as THREE from "three"
import { useFrame } from "@react-three/fiber"
import { GlobeObject } from "./GlobeObject"
import { Lighting } from "./Lighting"
import { DotsContainer } from "./Dots"
import { Atmosphere } from "./Atmosphere"

enum MOUSE_STATE {
  IS_DRAGGING,
  NOT_DRAGGING,
  TRANSITIONING
}

export const ParentContainer = () => {
  const [parentContainerRef, setParentContainerRef] = useState<
    THREE.Group | any
  >(null)
  const isDragging = useRef(MOUSE_STATE.NOT_DRAGGING)
  const prevMousePosition = useRef({
    x: 0,
    y: 0,
  })

  useFrame(() => {
    if (parentContainerRef && isDragging.current === MOUSE_STATE.NOT_DRAGGING) {
      parentContainerRef.rotation.y += 0.005
    }
  })

  useEffect(() => {
    if (
      parentContainerRef &&
      parentContainerRef.rotation &&
      !parentContainerRef.rotation.offset
    ) {
      parentContainerRef.rotation.offset = {
        x: 0,
        y: 0,
      }
    }
  }, [parentContainerRef])

  const onPointerDown = ({ clientX, clientY }: any) => {
    isDragging.current = MOUSE_STATE.IS_DRAGGING

    prevMousePosition.current = {
      x: clientX,
      y: clientY,
    }
  }

  const onPointerMove = ({ clientX, clientY }: any) => {
    if (isDragging.current === MOUSE_STATE.IS_DRAGGING) {
      const deltaX = clientX - prevMousePosition.current.x
      const deltaY = clientY - prevMousePosition.current.y

      const xTop = 0.5
      const xBottom = -0.5

      parentContainerRef.rotation.offset.y += deltaX * 0.005

      let xVal = parentContainerRef.rotation.offset.x + deltaY * 0.005

      if (xVal > xTop) {
        xVal = xTop
      } else if (xVal < xBottom) {
        xVal = xBottom
      }

      parentContainerRef.rotation.offset.x = xVal

      gsap.to(parentContainerRef.rotation, {
        y: parentContainerRef.rotation.offset.y,
        x: xVal,
        duration: 2,
      })

      if (xVal < xTop && xVal > xBottom) {
        prevMousePosition.current = {
          x: clientX,
          y: clientY,
        }
      }
    }
  }

  const onPointerRelease = () => {
    isDragging.current = MOUSE_STATE.TRANSITIONING

    setTimeout(() => {
      if (isDragging.current !== MOUSE_STATE.IS_DRAGGING) {
        isDragging.current = MOUSE_STATE.NOT_DRAGGING
      }
    }, 1000);
  }

  return (
    <>
      <group
        ref={setParentContainerRef}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerRelease}
        onPointerLeave={onPointerRelease}
      >
        {parentContainerRef ? (
          <>
            <GlobeObject parentContainerRef={parentContainerRef} />
            <DotsContainer />
          </>
        ) : null}
      </group>
      {parentContainerRef ? (
        <>
          <Lighting parentContainerRef={parentContainerRef} />
          <Atmosphere />
        </>
      ) : null}
    </>
  )
}
