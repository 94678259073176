enum Platforms {
  FREELANCER = "freelancer",
  GITHUB = "github",
  GMAIL = "gmail",
  LINKEDIN = "linkedin",
  UPWORK = "upwork",
}

const SiteLink = {
  [Platforms.FREELANCER]: "https://www.freelancer.com/u/deepaknatraj254",
  [Platforms.GITHUB]: "https://github.com/deepak25495",
  [Platforms.GMAIL]: "mailto:deepaknatarajan25495@gmail.com",
  [Platforms.LINKEDIN]: "https://www.linkedin.com/in/deepaknatraj25",
  [Platforms.UPWORK]: "https://www.upwork.com/freelancers/~013368dede56a45bd2",
}

export const contactList = [
  [
    {
      siteLink: SiteLink.linkedin,
      icon: Platforms.LINKEDIN,
      medium: Platforms.LINKEDIN,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.gmail,
      icon: Platforms.GMAIL,
      medium: Platforms.GMAIL,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.freelancer,
      icon: Platforms.FREELANCER,
      medium: Platforms.FREELANCER,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.upwork,
      icon: Platforms.UPWORK,
      medium: Platforms.UPWORK,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
  ],
  [
    {
      siteLink: SiteLink.github,
      icon: Platforms.GITHUB,
      medium: Platforms.GITHUB,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.upwork,
      icon: Platforms.UPWORK,
      medium: Platforms.UPWORK,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.gmail,
      icon: Platforms.GMAIL,
      medium: Platforms.GMAIL,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.freelancer,
      icon: Platforms.FREELANCER,
      medium: Platforms.FREELANCER,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
  ],
  [
    {
      siteLink: SiteLink.gmail,
      icon: Platforms.GMAIL,
      medium: Platforms.GMAIL,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.github,
      icon: Platforms.GITHUB,
      medium: Platforms.GITHUB,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.freelancer,
      icon: Platforms.FREELANCER,
      medium: Platforms.FREELANCER,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.linkedin,
      icon: Platforms.LINKEDIN,
      medium: Platforms.LINKEDIN,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
  ],
  [
    {
      siteLink: SiteLink.freelancer,
      icon: Platforms.FREELANCER,
      medium: Platforms.FREELANCER,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.github,
      icon: Platforms.GITHUB,
      medium: Platforms.GITHUB,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.upwork,
      icon: Platforms.UPWORK,
      medium: Platforms.UPWORK,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
    {
      siteLink: SiteLink.gmail,
      icon: Platforms.GMAIL,
      medium: Platforms.GMAIL,
      username: "deepaknatraj25",
      description: "Take a look at my projects and feel free to fork on it!",
    },
  ],
]
