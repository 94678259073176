import { useThree } from "@react-three/fiber"
import React, { useEffect } from "react"

export const SceneCamera = () => {
  const { camera, size } = useThree()

  useEffect(() => {
    camera.position.set(0, 0, 55)
  }, [])

  return <perspectiveCamera args={[20, size.width / size.height, 170, 260]} />
}
