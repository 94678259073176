import { MEDIA_PLATFORM, PLATFORMS } from "../components/WorkDetail"

export const worksList: any = [
  {
    name: "SquireCircle",
    description: "Digital journey for every landlord. Life made easy.",
    techStack: ["REACT NATIVE", "NEST.JS", "GRAPHQL", "POSTGRES"],
    mediaPlatform: MEDIA_PLATFORM.MOBILE,
    mediaReference: {
      poster:
        "https://github.githubassets.com/images/modules/site/home/gh-mobile.png",
      sources: [
        {
          src: "https://github.githubassets.com/images/modules/site/home/gh-mobile.h264.mp4",
          type: "video/mp4",
        },
      ],
    },
    tryOnPlatforms: [PLATFORMS.IOS, PLATFORMS.ANDROID],
    actions: [
      {
        platform: PLATFORMS.IOS,
        to: "https://developer.apple.com",
      },
      {
        platform: PLATFORMS.ANDROID,
        to: "https://google.developer.com",
      },
    ],
  },

  {
    name: "SquireCircle",
    description: "Digital journey for every landlord. Life made easy.",
    techStack: ["REACT NATIVE", "NEST.JS", "GRAPHQL", "POSTGRES"],
    mediaPlatform: MEDIA_PLATFORM.WEB,
    mediaReference: {
      siteLink: "https:github.com",
      poster:
        "https://github.githubassets.com/images/modules/site/home/gh-mobile.png",
      sources: [
        {
          src: "https://github.githubassets.com/images/modules/site/home/gh-mobile.h264.mp4",
          type: "video/mp4",
        },
      ],
    },
    tryOnPlatforms: [PLATFORMS.WEB],
    actions: [
      {
        platform: PLATFORMS.WEB,
        to: "https://developer.apple.com",
      },
    ],
  },
  {
    name: "SquireCircle",
    description: "Digital journey for every landlord. Life made easy.",
    techStack: ["REACT NATIVE", "NEST.JS", "GRAPHQL", "POSTGRES"],
    mediaPlatform: MEDIA_PLATFORM.MOBILE,
    mediaReference: {
      poster:
        "https://github.githubassets.com/images/modules/site/home/gh-mobile.png",
      sources: [
        {
          src: "https://github.githubassets.com/images/modules/site/home/gh-mobile.h264.mp4",
          type: "video/mp4",
        },
      ],
    },
    tryOnPlatforms: [PLATFORMS.IOS, PLATFORMS.ANDROID],
    actions: [
      {
        platform: PLATFORMS.IOS,
        to: "https://developer.apple.com",
      },
      {
        platform: PLATFORMS.ANDROID,
        to: "https://google.developer.com",
      },
    ],
  },
  {
    name: "SquireCircle",
    description: "Digital journey for every landlord. Life made easy.",
    techStack: ["REACT NATIVE", "NEST.JS", "GRAPHQL", "POSTGRES"],
    mediaPlatform: MEDIA_PLATFORM.WEB,
    mediaReference: {
      siteLink: "https:github.com",
      poster:
        "https://github.githubassets.com/images/modules/site/home/gh-mobile.png",
      sources: [
        {
          src: "https://github.githubassets.com/images/modules/site/home/gh-mobile.h264.mp4",
          type: "video/mp4",
        },
      ],
    },
    tryOnPlatforms: [PLATFORMS.WEB],
    actions: [
      {
        platform: PLATFORMS.WEB,
        to: "https://developer.apple.com",
      },
    ],
  },
]
