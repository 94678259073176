import React from "react"

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  siteLink: string
  width?: number
  height?: string | number
}

export const BrowserWrapper: React.FC<IProps> = ({
  siteLink,
  children,
  width = 700,
  height = 615,
  className = "",
  ...rest
}) => {
  return (
    <div {...rest} className={`browser-container ${className}`} style={{ width, height }}>
      <a target="_blank" href={siteLink}>
        <div className="action_url">
          <p>{siteLink}</p>
        </div>
      </a>
      {children}
    </div>
  )
}
