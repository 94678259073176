import * as React from "react"
import { PageProps } from "gatsby"

import { Home } from "../sections/Home"
import { Skills } from "../sections/Skills"
import { About } from "../sections/About"
import { Works } from "../sections/Works"
import { Layout } from "../components/Layout"

const HomePage: React.FC<PageProps<any>> = () => (
  <Layout>
    <Home />
    <Skills />
    <About />
    <Works />
  </Layout>
)

export default HomePage
