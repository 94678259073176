import { useFrame } from "@react-three/fiber"
import React, { useLayoutEffect, useRef, useState } from "react"
import * as THREE from "three"
import { GLOBE_RADIUS } from "./GlobeObject"
import {
  calc_pos,
  DEG2RAD,
  getImageData,
  visibilityForCoordinate,
} from "./helper.functions"

const worldDotSize = 0.095
const worldDotRows = 200
const tempBoxes = new THREE.Object3D()

export const Dots = ({ imageData }: any) => {
  const [imgArray, setImgArray] = useState<any>([])
  const ref = useRef<THREE.InstancedMesh>(null)
  const geometry = useRef(new THREE.CircleGeometry(worldDotSize, 5))
  const material = useRef(
    new THREE.MeshStandardMaterial({
      color: 3818644,
      metalness: 0,
      roughness: 0.9,
      transparent: !0,
      alphaTest: 0.02,
    })
  )

  material.current.onBeforeCompile = t => {
    t.fragmentShader = t.fragmentShader.replace(
      "gl_FragColor = vec4( outgoingLight, diffuseColor.a );",
      "\n        gl_FragColor = vec4( outgoingLight, diffuseColor.a );\n        if (gl_FragCoord.z > 0.51) {\n          gl_FragColor.a = 1.0 + ( 0.51 - gl_FragCoord.z ) * 17.0;\n        }\n      "
    )
  }

  useLayoutEffect(() => {
    const tempArray = []
    for (let lat = -90; lat <= 90; lat += 180 / worldDotRows) {
      const radius = Math.cos(Math.abs(lat) * DEG2RAD) * GLOBE_RADIUS
      const circum = radius * Math.PI * 2 * 2
      for (let r = 0; r < circum; r++) {
        const lng = (360 * r) / circum - 180
        if (!visibilityForCoordinate(lng, lat, imageData)) continue
        const s = calc_pos(lat, lng, GLOBE_RADIUS)

        tempBoxes.position.set(s.x, s.y, s.z)
        const o = calc_pos(lat, lng, GLOBE_RADIUS + 5)
        tempBoxes.lookAt(o.x, o.y, o.z)
        tempBoxes.updateMatrix()
        tempArray.push(tempBoxes.matrix.clone())
      }
    }

    setImgArray(tempArray)
  }, [])

  useFrame(() => {
    if (ref.current) {
      for (let l = 0; l < imgArray.length; l++)
        ref.current.setMatrixAt(l, imgArray[l])
    }
  })

  return (
    <instancedMesh
      ref={ref}
      args={[geometry.current, material.current, imgArray.length]}
    />
  )
}

export const DotsContainer = () => {
  const [imageData, setImageData] = useState<ImageData | null>(null)
  const image = useRef(new Image())

  const onImageLoad = () => {
    setImageData(getImageData(image.current))
  }

  useLayoutEffect(() => {
    image.current.src = "./map.png"
    image.current.crossOrigin = "Anonymous"

    image.current.onload = onImageLoad
  }, [])

  return !imageData ? <group /> : <Dots imageData={imageData} />
}
