import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Globe } from "../components/Globe"

import "../styles/sections/home.scss"

export const Home = () => {
  return (
    <section className="home-section">
      <StaticImage
        src="./../images/global/curve_overlay.png"
        alt="Header Overlay"
        className="overlay_image"
        objectFit="fill"
      />
      <StaticImage
        src="./../images/global/astro-mona.webp"
        alt="Astro Mona"
        className="astro-mona"
        objectFit="contain"
      />
      <StaticImage
        src="./../images/global/hero-glow.png"
        alt="Hero Glow"
        className="hero-glow"
        objectFit="cover"
      />
      <div className="content">
        <header>
          <div className="container">
            <h4 className="fw-bold">deepak natarajan</h4>
          </div>
        </header>
        <div className="details">
          <div className="container">
            <div className="text-detail">
              <h1 className="title">It’s all about building software</h1>
              <h4 className="description fc-light">
                As a Software Engineer with an eye for design and user
                experience, I craft quality software from front to back - every
                aspect considered.
              </h4>
              <button>Contact Me</button>
            </div>
            <Globe />
          </div>
        </div>
      </div>
    </section>
  )
}
