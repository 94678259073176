import React from "react"
import gsap from "gsap"
import { Expo } from "gsap/gsap-core"

import {
  GitAction,
  GitBranch,
  GitLine,
  ICON_SIDE,
} from "../components/GitHints"
import { MEDIA_PLATFORM, PLATFORMS, WorkDetail } from "../components/WorkDetail"
import { worksList } from "../data/works_list"
import { useScrollProxy } from "../hooks/useScrollProxy.hooks"

import "../styles/sections/works.scss"

const wrapInCenter = (Component, align: "left" | "right" = "left") => (
  <div className={`inCenter ${align}`}>
    <div className="content-wrap">{Component}</div>
  </div>
)

const WorkContentMain = ({ children, align = "center", className = "" }) => (
  <div
    className={`works_content${className ? ` ${className}` : ""}`}
    style={{ justifyContent: align }}
  >
    {children}
  </div>
)

export const Works = () => {
  const initScrollAnims = () => {
    gsap.utils.toArray(".works_section .parallax-content").forEach((section: any, i) => {
      gsap.fromTo(
        section,
        {
          y: 100,
          opacity: 0,
          scale: 0.9
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            start: "top 75%",
            end: "bottom 25%",
            toggleActions: "restart reverse restart reverse"
          },
          y: 0,
          opacity: 1,
          scale: 1,
          ease: Expo.easeInOut,
        }
      )
    })
    gsap.utils.toArray(".works_section .detail_container.isOnRight").forEach((section: any, i) => {
      gsap.fromTo(
        section,
        {
          x: 100,
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            start: "top 50%",
            end: "bottom 25%",
            toggleActions: "restart reverse restart reverse"
          },
          x: 0,
          opacity: 1,
          ease: Expo.easeInOut
        }
      )
    })
    gsap.utils.toArray(".works_section .detail_container.isOnLeft").forEach((section: any, i) => {
      gsap.fromTo(
        section,
        {
          x: -100,
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            start: "top 50%",
            end: "bottom 25%",
            toggleActions: "restart reverse restart reverse"
          },
          x: 0,
          opacity: 1,
          ease: Expo.easeInOut
        }
      )
    })
  }

  useScrollProxy(initScrollAnims)

  return (
    <section className="works_section">
      <div className="container my-works_container">
        <WorkContentMain className="isInvisibleWhenSmall">
          <GitLine />
        </WorkContentMain>
        {wrapInCenter(
          <GitAction
            text="git checkout -b origin my-works"
            side={ICON_SIDE.RIGHT}
          />,
          "right"
        )}
        <WorkContentMain>
          <GitLine height={75} short />
        </WorkContentMain>
        {wrapInCenter(
          <div className="branch-wrap">
            <GitBranch
              side={ICON_SIDE.RIGHT}
              className="git_branch_item"
              text="origin/my-works"
            />
          </div>,
          "right"
        )}
        {wrapInCenter(
          <div className="work_highlight">
            <div className="work_text">
              <h2 className="gradient-text">My Works</h2>
              <h2>
                Some of my projects
                <br />
                worth mentioning
              </h2>
            </div>
            <GitLine />
          </div>,
          "right"
        )}
        {worksList.map(work => (
          <>
            {wrapInCenter(
              <GitAction
                text={`git commit -m "${work.mediaPlatform} App - ${work.name}"`}
                side={
                  work.mediaPlatform == MEDIA_PLATFORM.MOBILE
                    ? ICON_SIDE.LEFT
                    : ICON_SIDE.RIGHT
                }
              />,
              work.mediaPlatform == MEDIA_PLATFORM.MOBILE ? "left" : "right"
            )}
            <WorkDetail
              mediaClassName="parallax-content"
              name={work.name}
              description={work.description}
              techStack={work.techStack}
              mediaPlatform={work.mediaPlatform}
              mediaReference={work.mediaReference}
              tryOnPlatforms={work.tryOnPlatforms}
              actions={work.actions}
              reverse={work.mediaPlatform == MEDIA_PLATFORM.WEB}
            />
          </>
        ))}
        {wrapInCenter(
          <GitAction text="git push origin my-works" side={ICON_SIDE.RIGHT} />,
          "right"
        )}
        <WorkContentMain className="isInvisibleWhenSmall">
          <GitLine height={125} />
        </WorkContentMain>
      </div>
    </section>
  )
}
