import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { BrowserWrapper } from "./BrowserWrapper"

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  siteLink: string
  name: string
  role: string
  location: string
  bio: string
}

export const LinkedinAbout: React.FC<IProps> = ({
  siteLink,
  name,
  role,
  location,
  bio,
  ...rest
}) => {
  return (
    <div {...rest}>
      <BrowserWrapper siteLink={siteLink} width={430} height="auto">
        <div className="linkedin_about">
          <div className="card profile">
            <div className="banner_img_container">
              <StaticImage
                src="../images/global/profile_bg.png"
                alt="Linkedin Profile BG - Deepak Natarajan"
                className="banner_img"
                objectFit="fill"
              />
              <div className="profile_img_container">
                <StaticImage
                  src="../images/global/profile_pic.png"
                  alt="Profile Avatar - Deepak Natarajan"
                  className="profile_img"
                  objectFit="contain"
                />
              </div>
            </div>
            <div className="content">
              {name && <h5>{name}</h5>}
              {role && <p>{role}</p>}
              {location && <p className="light-color">{location}</p>}
            </div>
          </div>
          {bio == "" ? null : (
            <div className="card info">
              <b>Info</b>
              <p>{bio}</p>
            </div>
          )}
        </div>
      </BrowserWrapper>
    </div>
  )
}
