import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { IGatsbyImageDataParent } from "gatsby-plugin-image/dist/src/components/hooks"

import "../styles/sections/footer.scss"
import { ContactCard } from "../components/ContactCard"
import { Resume } from "../components/Resume"

import { contactList } from "../data/contact_list"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "contact_images" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      allContentfulResume(limit: 1) {
        edges {
          node {
            id
            fileName
            resumePdf {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const resumeData = data.allContentfulResume.edges[0].node;

  return (
    <>
      <Resume
        title={
          <>
            <span className="fc-light">Want this offline?</span> You can
            download my resume using the link below.
          </>
        }
        filename={resumeData.fileName}
        fileURL={resumeData.resumePdf.file.url}
        posterURL="https://repository-images.githubusercontent.com/234178430/1f140e00-3818-11ea-89e8-22bdfafd120a"
      />
      <footer className="footer_section">
        <StaticImage
          src="./../images/global/footer_img.png"
          alt="Footer Image"
          className="footer_image"
          objectFit="contain"
        />
        <StaticImage
          src="./../images/global/curve_overlay.png"
          alt="Footer Image"
          className="overlay_image"
          objectFit="fill"
        />
        <div className="content">
          <div className="detail">
            <h1>You can talk to me!</h1>
            <div className="spacer" />
            <h4 className="fc-light">
              Small experiment, big project, web app, mobile app, consulting or
              just a chat - feel free to contact me through any of the links
              here.
            </h4>
          </div>
          <div className="contact-list">
            {contactList.map((row, index) => (
              <div
                className={`contact-list__row row-${index + 1}`}
                key={`__${index}`}
              >
                {row.map((item, inner_index) => {
                  const icon = data.allFile.edges.find(
                    i => i.node.name == item.icon
                  )
                  return (
                    <ContactCard
                      {...item}
                      key={`${index}_${inner_index}`}
                      icon={
                        icon.node.childImageSharp
                          .gatsbyImageData as IGatsbyImageDataParent
                      }
                    />
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      </footer>
    </>
  )
}
