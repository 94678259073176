import React from "react"
import gsap from "gsap"
import { Expo } from "gsap/gsap-core"

import {
  GitBranch,
  GitAction,
  GitLine,
  THEME,
  GitLineHorizontal,
} from "../components/GitHints"
import { LinkedinAbout } from "../components/LinkedinAbout"
import { LinkedinExperience } from "../components/LinkedinExperience"
import { LinkedinSkills } from "../components/LinkedinSkills"
import {
  AboutLineIcon,
  ExperienceLineIcon,
  LineCurveStart,
  SkillsLineIcon,
} from "../components/SvgIcons"
import "../styles/sections/about.scss"
import { useScrollProxy } from "../hooks/useScrollProxy.hooks"

const AboutContentMain = ({ children }) => (
  <div className="about_content">{children}</div>
)

const AboutContentInside = ({ children }) => (
  <AboutContentMain>
    <GitLine />
    <div className="about_main">
      <div className="line_curve_start">
        <span className="curve">
          <LineCurveStart />
        </span>
        <span className="straight">
          <GitLine />
        </span>
      </div>
      <div className="about_main_content">{children}</div>
      <div className="line_curve_end">
        <span className="curve">
          <LineCurveStart />
        </span>
        <span className="straight">
          <GitLine />
        </span>
      </div>
    </div>
  </AboutContentMain>
)

export const About = () => {
  const initScrollAnims = () => {
    gsap.utils.toArray(".about_section .parallax-content").forEach((section: any, i) => {
      const widthDiff = section.offsetWidth / 1.5;
      gsap.fromTo(
        section,
        {
          x: widthDiff,
          opacity: 0.1,
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            start: "top 75%",
            end: "bottom 75%",
            toggleActions: "restart reverse restart reverse",
            scrub: true,
          },
          x: 0,
          opacity: 1,
          ease: Expo.easeInOut,
        }
      )
    })

    gsap.utils.toArray(".about_section .para").forEach((section: any, i) => {
      gsap.fromTo(
        section,
        {
          x: -50,
          opacity: 0,
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            toggleActions: "restart none none pause"
          },
          x: 0,
          opacity: 1,
          ease: Expo.easeInOut,
        }
      )
    })
  }

  useScrollProxy(initScrollAnims)

  return (
    <section className="about_section">
      <div className="container about_me_container">
        <div className="git_branch_container">
          <AboutContentMain>
            <GitLine short />
          </AboutContentMain>
          <GitAction
            theme={THEME.ON_PRIMARY}
            text="git checkout -b origin about-me"
          />
          <AboutContentInside>
            <GitBranch
              theme={THEME.ON_PRIMARY}
              className="git_branch_item"
              text="origin/about-me"
            />
            <AboutContentMain>
              <GitLine />
              <div className="about_text">
                <h2 className="gradient-text">About Me</h2>
                <h2>
                  Your Partner in
                  <br />
                  Web &amp; Mobile Technology
                </h2>
              </div>
            </AboutContentMain>
            <AboutContentMain>
              <GitLine />
              <div className="about_main_row">
                <div className="about_text">
                  <div className="line_icon_container">
                    <AboutLineIcon />
                  </div>
                  <p className="para">
                    As a{" "}
                    <span className="fc-light">
                      full-stack software engineer
                    </span>{" "}
                    I enjoy designing highly interactive user interfaces that
                    enthuse customers and creating scalable, reliable backend
                    solutions - but also caring about the other parts that are
                    needed for a
                    <span className="fc-light">
                      {" "}
                      successful software project
                    </span>
                    : Consistency in design, good planning of UI and thinking
                    how it affects the UX, Search Engine Optimization and
                    maintaining the software after initial release.
                  </p>
                </div>
                <LinkedinAbout
                  className="parallax-content"
                  siteLink="https://linkedin.com"
                  name="Deepak Natarajan"
                  role="Software Engineer"
                  location="Chennai, India"
                  bio="Experienced Frontend developer with a demonstrated history of working in the design industry. Skilled in HTML, CSS, JavaScript (Angular, Ionic Framework, React and Node). Hands-on experience in developing for web and hybrid mobile applications."
                />
              </div>
            </AboutContentMain>
            <AboutContentMain>
              <GitLine />
              <div className="about_main_row">
                <div className="about_text">
                  <div className="line_icon_container">
                    <ExperienceLineIcon />
                  </div>
                  <p className="para">
                    While I was working for various companies across the globe,
                    I've had the privilege to learn about their unique
                    challenges of creating software that's{" "}
                    <span className="fc-light">
                      best suited for their business
                    </span>
                    . Whether it's been in the health care, logistics or finance
                    sector - it's important to know the external influences that
                    may impact the software project, and how the project itself
                    fits into the{" "}
                    <span className="fc-light">bigger picture</span>.
                  </p>
                </div>
                <LinkedinExperience
                  className="parallax-content"
                  siteLink="https://linkedin.com"
                  experience={[
                    {
                      role: "Software developer",
                      company: "SDTECH (Formerly Studio Diseño)",
                      period: "Dec 2016 - Present5 years 3 months",
                      location: "Chennai Area, India",
                    },
                  ]}
                  education={[
                    {
                      college_name: "Karpagam college of enginerring",
                      degree:
                        "Bachelor of Engineering - BE | Computer Software Engineering",
                      period: "2012 - 2016",
                    },
                  ]}
                />
              </div>
            </AboutContentMain>
            <AboutContentMain>
              <GitLine />
              <div className="about_main_row">
                <div className="about_text">
                  <div className="line_icon_container">
                    <SkillsLineIcon />
                  </div>
                  <p className="para">
                    I have{" "}
                    <span className="fc-light">
                      maintained, developed and launched multiple projects from
                      scratch
                    </span>
                    , carrying the development of it's backend and frontend
                    codebases. My current toolset includes
                    TypeScript/JavaScript, React, Vue, Node.js and all the other
                    various frameworks, libraries and technologies related to
                    them.
                  </p>
                </div>
                <LinkedinSkills
                  className="parallax-content"
                  siteLink="https://linkedin.com"
                  skills={[
                    {
                      type: "Frontend Technologies",
                      list: [
                        "Javascript",
                        "Typescript",
                        "React",
                        "React Native",
                        "Flutter",
                      ],
                    },
                    {
                      type: "Backend Technologies",
                      list: [
                        "Node.js",
                        "Nest.js",
                        "PHP",
                        "MongoDB",
                        "PostgresSQL",
                        "GraphQL",
                      ],
                    },
                    {
                      type: "Learning more...",
                      list: ["Docker", "AWS", "CI/CD", "Terraform"],
                    },
                  ]}
                />
              </div>
            </AboutContentMain>
            <GitLine />
            <GitAction theme={THEME.ON_PRIMARY} text="git merge about-me" />
          </AboutContentInside>
          <div className="foot-group">
            <AboutContentMain>
              <div className="foot-line">
                <GitLine />
              </div>
            </AboutContentMain>
            <AboutContentMain>
              <GitBranch theme={THEME.ON_PRIMARY} className="git_branch_item" />
            </AboutContentMain>
            <AboutContentMain>
              <div className="foot-line">
                <GitLine />
              </div>
            </AboutContentMain>
            <AboutContentMain>
              <GitLineHorizontal width="50%" />
            </AboutContentMain>
            <AboutContentMain>
              <div className="align-center">
                <GitLine />
              </div>
            </AboutContentMain>
          </div>
        </div>
      </div>
    </section>
  )
}
