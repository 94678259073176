import React, { useEffect, useRef } from "react"
import * as THREE from "three"

import atmosphereVertexShader from "./shaders/atmosphereVertex.glsl"
import atmosphereFragmentShader from "./shaders/atmosphereFragment.glsl"
import { Mesh } from "three"

export const ATMOSPHERE_RADIUS = 27.5
export const ATMOSPHERE_DETAIL = 50

export const Atmosphere = () => {
  const ref = useRef<Mesh>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.rotation.z += 2
    }
  }, []);

  return (
    <mesh ref={ref} scale={[1, 0.85, 1]} position={[-4, 3, 0]}>
      <sphereGeometry attach="geometry" args={[ATMOSPHERE_RADIUS, ATMOSPHERE_DETAIL, ATMOSPHERE_DETAIL]} />
      <shaderMaterial
        attach="material"
        vertexShader={atmosphereVertexShader}
        fragmentShader={atmosphereFragmentShader}
        blending={THREE.AdditiveBlending}
        side={THREE.BackSide}
      />
    </mesh>
  )
}
