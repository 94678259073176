import React, { useCallback } from "react"
import { Canvas } from "@react-three/fiber"

import { SceneCamera } from "./SceneCamera"
import { ParentContainer } from "./ParentContainer"

export const Globe = () => {
  const canvasProps = useCallback(() => {
    if (typeof window === "undefined") {
      return {}
    }
    return {
      dpr: window.devicePixelRatio,
      className: "globe",
    }
  }, [])

  return (
    <div className="globe-container">
      <Canvas {...canvasProps()}>
        <SceneCamera />
        <ParentContainer />
      </Canvas>
    </div>
  )
}
