import React from "react"

import MomentumScroll from "./MomentumScroll"
import { Footer } from "../sections/Footer"
import { Copyright } from "../sections/Copyright"

export const Layout = ({ children }) => {
  return (
    <div id="layout-container">
      <MomentumScroll>
        {children}
        <Footer />
        <Copyright />
      </MomentumScroll>
    </div>
  )
}
