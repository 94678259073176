import { GatsbyImage } from "gatsby-plugin-image"
import { IGatsbyImageDataParent } from "gatsby-plugin-image/dist/src/components/hooks"
import React from "react"

interface IProps {
  siteLink: string
  icon: IGatsbyImageDataParent
  medium: string
  username: string
  description: string
}

export const ContactCard: React.FC<IProps> = ({
  siteLink,
  icon,
  medium,
  username,
  description,
}) => {
  return (
    <a href={siteLink} target="_blank">
      <div className="contact_card">
        <div className="header">
          <div className="icon_container">
            <GatsbyImage image={icon} alt={medium} className="icon" objectFit="contain"/>
          </div>

          <span className="contact_detail">
            <b className="color-light fw-semibold">{medium.toLowerCase()}</b>
            <p>/</p>
            <p className="fw-semibold">{username}</p>
          </span>
        </div>
        <p className="description color-light">{description}</p>
      </div>
    </a>
  )
}
