import React, { useRef, useEffect } from 'react';
import SmoothScrollbar from 'smooth-scrollbar';

import { updateGlobals, EVENTS } from '../helpers/globals';

const options = {
  damping: 0.1,
  thumbMinSize: 10,
  renderByPixels: true,
  alwaysShowTracks: false,
  continuousScrolling: true,
  delegateTo: null,
  plugins: {},
}

const MomentumScroll: React.FC<any> = ({ children, onScroll }) => {
  const scrollbarRef = useRef(null);
  const containerRef = useRef(null);
  const eventRef = useRef(null);

  const handleScroll = (status) => {
    if (onScroll) {
      onScroll(status, scrollbarRef.current);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      eventRef.current = new Event(EVENTS.onSmoothScrollInit);
    }

    scrollbarRef.current = SmoothScrollbar.init(containerRef.current, options);
    scrollbarRef.current.addListener(handleScroll);

    updateGlobals('SMOOTH_SCROLL_CONTEXT', scrollbarRef.current);

    setTimeout(() => {
      window.dispatchEvent(eventRef.current);
    }, 10);

    return () => {
      scrollbarRef.current.destroy();
      updateGlobals('SMOOTH_SCROLL_CONTEXT', '');
      eventRef.current = null;
    };
  }, []);

  return (
    <div data-scrollbar id="dn-momentum-scroll" className="dn-momentum-scroll" ref={containerRef}>
      {children}
    </div>
  );
};

export default MomentumScroll;