import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { Circ } from "gsap/gsap-core"
import React, { useRef } from "react"
import { useScrollProxy } from "../hooks/useScrollProxy.hooks"

import "../styles/sections/skills.scss"

export const Skills = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "skills_images" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const initScrollAnims = () => {
    gsap.utils.toArray(".skills-content").forEach((section: any, i) => {
      gsap.fromTo(
        section,
        {
          scale: 0.85,
          opacity: 0.85,
        },
        {
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "top 50%",
            toggleActions: "restart none none reverse",
          },
          scale: 1,
          opacity: 1,
          ease: Circ.easeInOut,
        }
      )
    })
  }

  useScrollProxy(initScrollAnims)

  return (
    <section className="skills_section">
      <div className="container">
        <div className="skills-content">
          <div className="banner">
            <h3 className="title">
              Optimal Solutions require appropriate Tools
            </h3>
            <p className="description">
              Tools used to reach the <span className="highlight">optimal</span>{" "}
              software <span className="highlight">solution</span>...
            </p>
          </div>
          <div className="skills_list">
            {edges.map((skill, index) => (
              <GatsbyImage
                key={`${index}`}
                image={skill.node.childImageSharp.gatsbyImageData}
                alt={`Skill ${skill.node.name}`}
                className="skill"
                objectFit="contain"
                objectPosition="center"
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
