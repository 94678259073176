import React from "react"
import { GLOBE_RADIUS } from "./GlobeObject"

interface IProps {
  parentContainerRef: THREE.Group
}

export const Lighting: React.FC<IProps> = ({ parentContainerRef }) => {
  return (
    <>
      <ambientLight args={[16777215, 0.8]} />
      <directionalLight
        args={[11124735, 3]}
        position={[
          parentContainerRef.position.x - 50,
          parentContainerRef.position.y + 30,
          10,
        ]}
      />
      <directionalLight
        args={[11124735, 3]}
        position={[
          parentContainerRef.position.x - 25,
          parentContainerRef.position.y + 15,
          -35,
        ]}
      />
      <spotLight
        args={[2197759, 12, 120, 0.3, 0, 1.1]}
        position={[parentContainerRef.position.x - 2.5 * GLOBE_RADIUS, 80, -40]}
        target={parentContainerRef}
      />
      <spotLight
        args={[16018366, 5, 75, 0.5, 0, 1.25]}
        position={[
          parentContainerRef.position.x + GLOBE_RADIUS,
          GLOBE_RADIUS,
          2 * GLOBE_RADIUS,
        ]}
        distance={75}
        target={parentContainerRef}
      />
    </>
  )
}
