import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FullScreen, Spinner } from "./SvgIcons"

interface IProps {
  title: string | React.ReactElement<any, any>
  filename: string
  fileURL: string
  posterURL: string
}

export const Resume: React.FC<IProps> = ({
  title,
  filename,
  fileURL,
  posterURL,
}) => {
  return (
    <div className="resume">
      <h3 className="title">{title}</h3>
      <div className="resume_card">
        <div className="resume_header">
          <h5 className="filename">{filename}</h5>

          <a href={fileURL} target="_black" className="resume_action">
            <FullScreen />
            Full Screen
          </a>
        </div>

        <div className="resume_contatiner">
          <div className="resume_tab_item">
            <small className="fw-semibold">Resume</small>
          </div>
          <div className="resume_preview">
            <embed
              src={`${fileURL}#toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className="spinner">
        <Spinner />
      </div>
    </div>
  )
}
