import { useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { getGlobals, EVENTS } from "../helpers/globals"

gsap.registerPlugin(ScrollTrigger)

export const useScrollProxy = (callback: () => void) => {
  const scroller = useRef(null)
  const momentumScrollContext = useRef(getGlobals("SMOOTH_SCROLL_CONTEXT"))

  const setUpScrollProxy = () => {
    scroller.current = document.querySelector(`#dn-momentum-scroll`)
    ScrollTrigger.scrollerProxy(scroller.current, {
      scrollTop(value) {
        if (arguments.length) {
          if (momentumScrollContext.current) {
            momentumScrollContext.current.scrollTop = value
          }
        }
        return momentumScrollContext.current?.scrollTop
      },
    })
    if (momentumScrollContext.current) {
      momentumScrollContext.current?.addListener(ScrollTrigger.update)
    }
    ScrollTrigger.defaults({ scroller: scroller.current })
    callback()
  }

  useEffect(() => {
    window.addEventListener(EVENTS.onSmoothScrollInit, () => {
      momentumScrollContext.current = getGlobals("SMOOTH_SCROLL_CONTEXT")
      setUpScrollProxy()
    })
  }, [])

  return {
    scroller,
    momentumScrollContext,
  }
}
