import React from "react"

import "../styles/sections/copyright.scss"

export const Copyright = () => {
  return (
    <section className="copyright_section">
      <div className="container">
        <small>© 2022 Deepak Natarajan.</small>
        <small>
          Design inspired by <a href="https://github.com">Github</a>.
        </small>
      </div>
    </section>
  )
}
