import React, { ReactElement } from "react"
import { GitActionIcon, GitBranchDotIcon, GitBranchIcon } from "./SvgIcons"

export enum THEME {
  ON_PRIMARY,
  ON_BACKGROUND,
}

export enum ICON_SIDE {
  LEFT,
  RIGHT,
}

export const GitAction = ({
  side = ICON_SIDE.LEFT,
  theme = THEME.ON_BACKGROUND,
  text,
}) => {
  return (
    <div
      className={`git_container ${side == ICON_SIDE.LEFT ? "left" : "right"} ${
        theme == THEME.ON_BACKGROUND ? "onbackground" : "onprimary"
      }`}
    >
      {wrapInIconContainer(<GitActionIcon />, theme)}
      <small className="text text-mono">{text}</small>
    </div>
  )
}

export const GitBranch = ({
  side = ICON_SIDE.LEFT,
  theme = THEME.ON_BACKGROUND,
  className = "",
  text = "",
}) => {
  return (
    <div
      className={`git_container ${side == ICON_SIDE.LEFT ? "left" : "right"} ${
        theme == THEME.ON_BACKGROUND ? "onbackground" : "onprimary"
      }${className ? ` ${className}` : ""}`}
    >
      {wrapInIconContainer(<GitBranchDotIcon />, theme)}
      {text ? (
        <div
          className={`git_branch_container ${
            side == ICON_SIDE.LEFT ? "left" : "right"
          }`}
        >
          <GitBranchIcon />
          <small className="text_inner text-mono">{text}</small>
        </div>
      ) : null}
    </div>
  )
}

export const GitLine: React.FC<{
  height?: string | number
  short?: boolean
}> = ({ height = "auto", short = false }) => (
  <div
    className={`gitline${short ? " is-short" : ""}`}
    style={{ height, minHeight: 50 }}
  />
)

export const GitLineHorizontal: React.FC<{
  width?: string | number
  short?: boolean
}> = ({ width = "auto" }) => (
  <div
    className="gitline-horizontal"
    style={{ width, minWidth: 50 }}
  />
)

export const wrapInIconContainer = (component: ReactElement, theme?: THEME) => {
  return <div className="icon_container">{component}</div>
}
