import React, { useRef } from "react"
import * as THREE from "three"

import vertex from "./shaders/globeVertex.glsl"
import fragment from "./shaders/globeFragment.glsl"

export const GLOBE_RADIUS = 25
export const GLOBE_DETAIL = 55

interface IProps {
  parentContainerRef: THREE.Group
}

export const GlobeObject: React.FC<IProps> = ({ parentContainerRef }) => {
  const shadowPoint = useRef(
    new THREE.Vector3()
      .copy(parentContainerRef.position)
      .add(
        new THREE.Vector3(0.7 * GLOBE_RADIUS, 0.3 * -GLOBE_RADIUS, GLOBE_RADIUS)
      )
  )
  const highlightPoint = useRef(
    new THREE.Vector3()
      .copy(parentContainerRef.position)
      .add(new THREE.Vector3(1.5 * -GLOBE_RADIUS, 1.5 * -GLOBE_RADIUS, 0))
  )
  const frontPoint = useRef(
    new THREE.Vector3()
      .copy(parentContainerRef.position)
      .add(new THREE.Vector3(0, 0, GLOBE_RADIUS))
  )
  const defaultPropsRef = useRef({
    shadowDist: 1.5 * GLOBE_RADIUS,
    highlightColor: 5339494,
    highlightDist: 5,
    frontHighlightColor: 2569853,
    waterColor: 1513012,
    landColorFront: 16777215,
    landColorBack: 16777215,
  })
  const uniforms = useRef<any>([])

  return (
    <mesh>
      <sphereGeometry args={[GLOBE_RADIUS, GLOBE_DETAIL, GLOBE_DETAIL]} />
      <meshStandardMaterial
        color={defaultPropsRef.current.waterColor}
        metalness={0}
        roughness={0.9}
        onBeforeCompile={t => {
          t.uniforms.shadowDist = {
            value: defaultPropsRef.current.shadowDist,
          }
          t.uniforms.highlightDist = {
            value: defaultPropsRef.current.highlightDist,
          }
          t.uniforms.shadowPoint = {
            value: new THREE.Vector3().copy(shadowPoint.current),
          }
          t.uniforms.highlightPoint = {
            value: new THREE.Vector3().copy(highlightPoint.current),
          }
          t.uniforms.frontPoint = {
            value: new THREE.Vector3().copy(frontPoint.current),
          }
          t.uniforms.highlightColor = {
            value: new THREE.Color(defaultPropsRef.current.highlightColor),
          }
          t.uniforms.frontHighlightColor = {
            value: new THREE.Color(defaultPropsRef.current.frontHighlightColor),
          }
          t.vertexShader = vertex
          t.fragmentShader = fragment
          uniforms.current.push(t.uniforms)
        }}
        defines={{
          USE_HIGHLIGHT: 1,
          USE_HIGHLIGHT_ALT: 1,
          USE_FRONT_HIGHLIGHT: 1,
          DITHERING: 1,
        }}
      />
    </mesh>
  )
}
