import React from "react"
import { Browser } from "./Browser"
import { Phone } from "./Phone"
import { AndroidIcon, BrowserIcon, IosIcon } from "./SvgIcons"
import { GitLine } from "./GitHints"

export enum MEDIA_PLATFORM {
  MOBILE = "mobile",
  WEB = "browser",
}

export enum PLATFORMS {
  IOS = "iOS",
  ANDROID = "android",
  WEB = "browser",
}

interface ISource {
  src: string
  type: "video/mp4" | "video/webm" | "video/ogg" | string
}

interface IBrowserMediaReference {
  siteLink: string
  poster: string
  sources: ISource[]
}

interface IMobileMediaReference {
  poster: string
  sources: ISource[]
}

interface IAction {
  platform: PLATFORMS
  to: string
}

interface IBaseProps {
  name: string
  description: string
  techStack: string[]
  tryOnPlatforms: PLATFORMS[]
  actions: IAction[]
  reverse?: boolean
  mediaClassName?: string
}

interface IBrowserProps extends IBaseProps {
  mediaPlatform: MEDIA_PLATFORM.WEB
  mediaReference: IBrowserMediaReference
}

interface IMobileProps extends IBaseProps {
  mediaPlatform: MEDIA_PLATFORM.MOBILE
  mediaReference: IMobileMediaReference
}

const getMediaComponent = (data: IBrowserProps | IMobileProps) => {
  if (data.mediaPlatform == MEDIA_PLATFORM.MOBILE) {
    return (
      <div className="phone_spacer">
        <Phone {...data.mediaReference} />
      </div>
    )
  } else if (data.mediaPlatform == MEDIA_PLATFORM.WEB) {
    return <Browser {...data.mediaReference} />
  }
}

const platform_icons = {
  [PLATFORMS.IOS]: IosIcon,
  [PLATFORMS.ANDROID]: AndroidIcon,
  [PLATFORMS.WEB]: BrowserIcon,
}

export const WorkDetail: React.FC<IBrowserProps | IMobileProps> = props => {
  const {
    name,
    description,
    techStack,
    mediaPlatform,
    tryOnPlatforms,
    actions,
    reverse = false,
    mediaClassName,
  } = props
  return (
    <div className={`work_detail${reverse ? " rtl" : " ltr"}`}>
      <div
        className={`work_media${
          reverse ? " isOnRight" : " isOnLeft"
        } ${mediaClassName}`}
      >
        {getMediaComponent(props)}
      </div>
      <div
        className={`detail_container${reverse ? " isOnRight" : " isOnLeft"}`}
      >
        <h3>
          {name}. <span className="fc-light">{description}</span>
        </h3>
        <h5 className="fc-grey">
          {techStack.map((item, index) => (
            <span key={`${techStack}-${index}`}>
              {item}
              {index < techStack.length - 1 ? " | " : ""}
            </span>
          ))}
        </h5>
        <h5 className="fc-light">
          {mediaPlatform == MEDIA_PLATFORM.WEB
            ? "Visit site on web or mobile browser"
            : tryOnPlatforms.length == 1
            ? `Available for ${tryOnPlatforms}`
            : `Available for ${tryOnPlatforms
                .join(" , ")
                .replace(/,([^,]*)$/, "and $1")}`}
        </h5>
        <div className="actions_container">
          {actions.map((action, index) => (
            <a key={index} href={action.to} target="_blank">
              {platform_icons[action.platform]}
            </a>
          ))}
        </div>
      </div>
      <div className="gitline-container">
        <GitLine height="100%" />
      </div>
    </div>
  )
}
