import React from "react"
import { BrowserWrapper } from "./BrowserWrapper"

interface ISkills {
  type: string
  list: string[]
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  siteLink: string
  skills: ISkills[]
}

export const LinkedinSkills: React.FC<IProps> = ({
  siteLink,
  skills,
  ...rest
}) => {
  return (
    <div {...rest}>
      <BrowserWrapper siteLink={siteLink} width={430} height="auto">
        <div className="linkedin_skills">
          <b className="title">Knowledge &amp; Skills</b>
          {skills.length == 0 ? (
            <div>
              <small className="light">Skills not defined yet</small>
            </div>
          ) : null}
          {skills.map((skill, index) => (
            <div className="skill_type_container" key={index}>
              <p className="skill_type_title light">{skill.type}</p>
              <div className="skill_list_container">
                {skill.list.map((item, index) => (
                  <small className="fw-semibold" key={index}>
                    {item}
                  </small>
                ))}
              </div>
            </div>
          ))}
        </div>
      </BrowserWrapper>
    </div>
  )
}
